<template>
	<div class="inner">
		<img src="../assets/about-banner.png" width="100%" alt="" />
		<div class="container">
			<div class="all-title over tc mt50 pt20 pb16 el-border-bottom mb20">
				<div class="ft36">关于我们</div>
			</div>
			<div class="l18 ft16 bl6 mt40">
				<div v-html="companyInfo.details"></div>
			</div>
			<!--<div class="mb50">-->
				<!--<img src="../assets/about-detals-bg.png" width="100%" class="mt50 mb50" />-->
			<!--</div>-->
		</div>
	</div>
</template>

<script>
	import { getAboutUs } from '@/api/index.js'
	export default {
		data() {
			return {
				current: 0,
				companyInfo:{
					id: '',
					name: '',
					companyDesc: '',
					details: ''
				},
			}
		},
		created(){
			this.getAboutUsInfo()
		},
		methods: {
			//导航切换
			changeNav(index) {
				this.current = index
			},
			/*** 关于我们 **/
			getAboutUsInfo(){
				getAboutUs().then(res => {
					if(res.code === 200){
						this.companyInfo = {
							id: res.data.id,
							name: res.data.name,
							companyDesc: res.data.companyDesc,
							details: res.data.details
						}
					}
				})
			},
		}
	}
</script>

<style scoped="scoped">

</style>
